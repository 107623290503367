
import './App.css';
import Insurancedeals from './Insurancedeals/Insurancedeals';
import Header from './Header/Header';

function App() {
  return (
      <div class="background">
  <ul class="circles">
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </ul>
       <Header/>
      <Insurancedeals/>
     
    </div>
  );
}

export default App;
