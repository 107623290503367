import React from 'react'
import "./Footer.css"
const Footer = () => {
  return (
    <div className='footer-container'>
      &copy;{new Date().getFullYear()} , All rights reserved by Marketdeals  
    </div>
  )
}

export default Footer